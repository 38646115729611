import { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const ComingSoon = () => {
    const [emailValue, setEmailValue] = useState('');
    const [resp, setResp] = useState('');
    const [lang, setLang] = useState('en');

    useEffect(() => {
        var userLang = navigator.language;
        if(userLang?.includes('it')) setLang('it');
        else setLang('en');
    }, []);   

    const translate = (key:string) =>{
        if(lang == 'en'){
            switch(key){
                case 'comingsoon':
                    return <>Coming<br></br>Soon</>;
                case 'joinus':
                    return <>Join us in the future of&nbsp;<b className="highContrast">Social Network-ing</b></>;
                case 'signup':
                    return 'Sign Up';
                case 'mailsubtitle':
                    return <>Join our newsletter to be the first to be notified when&nbsp;<span className="logoColor">MyBiznet</span>&nbsp;demo is available, and will be officially launched!</>;
            }
        }else if (lang == 'it'){
            switch(key){
                case 'comingsoon':
                    return <>Coming<br></br>Soon</>;
                case 'joinus':
                    return <>Unisciti a noi nel futuro dei&nbsp;<b className="highContrast">Social Network-ing</b></>;
                case 'signup':
                    return 'Iscriviti';
                case 'mailsubtitle':
                    return <>Iscriviti alla nostra newsletter per essere il primo a essere avvisato quando la demo di&nbsp;<span className="logoColor">MyBiznet</span>&nbsp;sarà disponibile e verrà lanciato ufficialmente!</>;
            }
        }
    }

    const validateEmail = (email:string) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const submitMail = () => {
        if(!validateEmail(emailValue)) return;
        else{
            try{
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ mail:emailValue })
                };
                fetch('api/register', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if(data?.errorMessage){
                            setResp(data?.errorMessage);
                            toast.error(data?.errorMessage, {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored"
                            });
                        }else{
                            setResp("ok");
                            toast.success("Mail registrata", {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored"
                            });
                        }
                    });
            }catch(err){
                setResp("Errore nella registrazione della mail");
            }
        }
    }

    return(
        <>
        <div className="ComingSoon">
            <div className="ComingSoon__background">
                <div className="noise"></div>
                <div className="overlay"></div>
            </div>
            <div className="ComingSoon__languageChanger">
                <div className={"option "+(lang=='en'?'active':'')} onClick={()=>setLang('en')}>EN</div>
                <div className={"option "+(lang=='it'?'active':'')} onClick={()=>setLang('it')}>IT</div>
            </div>
            <div className="ComingSoon__leftSection">
                <div className="ComingSoon__leftSection__logo noselect">
                    <img src={logo} className="App-logo" alt="logo" />
                    <span>MyBiznet</span>
                </div>
                <h1 className="ComingSoon__leftSection__title noselect">{translate('comingsoon')}</h1>
                <h3 className="ComingSoon__leftSection__subtitle noselect">{translate('joinus')}</h3>
                <div className="ComingSoon__leftSection__mailSection">
                    <label>Email*</label>
                    <input
                        spellCheck={false}
                        type="email"
                        value={emailValue}
                        onChange={e=>{setEmailValue(e.target.value); setResp('');}}
                    />
                    <button disabled={!validateEmail(emailValue) || resp === 'ok'} onClick={submitMail}>{translate('signup')}</button>
                    <h4 className="ComingSoon__leftSection__mailSection__mailSubtitle noselect">{translate('mailsubtitle')}</h4>
                </div>
            </div>
        </div>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            limit={1}
        />
        </>
    )
}

export default ComingSoon;